import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

const OptionalTooltip = ({
  title,
  children,
  disabled,
}: {
  title: string,
  disabled: boolean,
  children: React.ReactElement,
}) => {
  if (disabled) {
    return children
  }

  return (
    <Tooltip title={title}>
      {children}
    </Tooltip>
  )
}

export default OptionalTooltip
