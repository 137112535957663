export type Listener<T> = (value: T) => void
export type Canceller = () => void
export default class Notifier<T = void> {
  private listeners: Array<Listener<T>> = []

  publish(value: T) {
    this.listeners.forEach((l) => l(value))
  }

  subscribe(listener: Listener<T>): Canceller {
    this.listeners.push(listener)
    return () => this.unsubscribe(listener)
  }

  unsubscribe(listener: Listener<T>) {
    this.listeners = this.listeners.filter((l) => l !== listener)
  }

  unsubscribeAll() {
    this.listeners = []
  }
}
