import { immerable } from 'immer'

export default class Preferences {
  static [immerable] = true

  public readonly allowCookies!: boolean | null
 
  public constructor(fields: Pick<Preferences, 'allowCookies'>) {
    Object.assign(this, fields)
  }

  public static get default() {
    return new Preferences({
      allowCookies: null,
    })
  }
}
