import Auth0 from '../Infrastructure/Auth0'
import AccessToken from '../Domain/AccessToken'
import SlackHandle from '../Domain/SlackHandle'
import Account from '../Domain/Account'
import IAuthentication from '../Domain/IAuthentication'
import { Container } from 'typedi'

export class AccessDeniedAuthenticationError extends Error {}
export class UnknownAuthenticationError extends Error {
  constructor(
    public details: any
  ) {
    super()
  }
}
export class UnhandledSubError extends Error {
  constructor(
    public readonly sub: string
  ) {
    super(`Unrecognized sub in Auth0 user result: ${sub}`)
  }
}

export default class Authentication implements IAuthentication {
  private static subPrefix: string = 'oauth2|helpful-engineering-slack|'
  private auth0: Auth0

  constructor(container: typeof Container) {
    this.auth0 = container.get(Auth0)
  }

  isAuthenticated() {
    return this.auth0.isAuthenticated()
  }

  async current(): Promise<Account | null> {
    const user = await this.auth0.getUser()
    if (!user) return null

    if (!user.sub.startsWith(Authentication.subPrefix)) {
      throw new UnhandledSubError(user.sub)
    }

    const token = await this.auth0.getTokenSilently()
    return new Account({
      accessToken: new AccessToken(token),
      email: user.email,
      nickname: user.nickname,
      slackHandle: new SlackHandle(user.name),
      slackId: user.sub.replace(Authentication.subPrefix, ''),
      avatar: new URL(user.picture),
    })
  }

  async login() {
    try {
      await this.auth0.login()
    } catch (e) {
      if (e.error === 'access_denied') {
        throw new AccessDeniedAuthenticationError()
      }
      throw new UnknownAuthenticationError(e)
    }
  }

  async logout() {
    await this.auth0.logout()
  }
}
