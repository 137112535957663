import { createSchema as S, Validated } from 'ts-json-validator'
import Slack from "../Domain/Slack"
import ISlackChannelsRepository from '../Domain/ISlackChannelsRepository'

export default class SlackChannelsRepository implements ISlackChannelsRepository {
  private apiBaseUrl(): URL {
    const url = new URL(process.env.REACT_APP_API_BASE!)
    if (!url.pathname.endsWith('/')) url.pathname += '/'
    return url
  }
 

  async getSlackChannels(): Promise<any> {
    let url = this.apiBaseUrl().toString()
    url += `helpful/slack/channels`
    const response = await fetch(url, {
      method: 'GET',
      })
    const json = await response.json()
   
    return json.map((item: Validated<typeof slackChannelSchema>) => ({
      id: item.id,
      fields: new Slack({
        "Name": item.fields["Name"]?? null,
        "Created": item.fields["Created"]?? null,
        "Total membership": item.fields["Total membership"]?? null,
        "Channel ID": item.fields["Channel ID"]?? null,
        "Last Modified": item.fields["Last Modified"]?? null,
        "Slack Channel Link": item.fields["Slack Channel Link"]?? null

      }), 
      createdTime: item.createdTime       
    }))
  }

}

const slackChannelSchema = S({
  type: 'object',
  required: [ 'id', 'createdTime', 'fields' ],
  properties: {
    id: S({ type: 'string' }),
    authorized: S({type: "boolean"}),
    createdTime: S({ type: 'string', format: 'date-time' }),
    fields: S({
      type: 'object',
      required: [
        "Name", 
        "Channel ID",
      ],
      properties: {
        "Name":S({ type: 'string' }),
        "Created": S({ type: 'string' }),
        "Total membership": S({ type: 'number' }),
        "Channel ID": S({ type: 'string' }),
        "Last Modified": S({ type: 'string' }),
        "Slack Channel Link": S({ type: 'string' })

      }
  })
}
})
