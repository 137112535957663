import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import logoPath from '../Assets/helpful-engineering-favicon.png'
import { useCurrentAccount, useLogin, useLogout, usePreferences } from '../Domain/UseCases'
import OptionalTooltip from '../Components/OptionalTooltip'
import { Link } from 'react-router-dom'
import { Menu, MenuItem} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

/**
 * TODO There's a lot of application logic currently embedded in this component. It might be a good idea
 * to extract it.
 */
export default ({
  height,
} : {
  height?: number
}) => {
  const { data: preferences, status: preferencesStatus, isFetching: preferencesIsFetching } = usePreferences()
  const { data: currentAccount, status: currentAccountStatus, isFetching: currentAccountIsFetching } = useCurrentAccount()
  const [login, { status: loginStatus }] = useLogin()
  const [logout, { status: logoutStatus }] = useLogout()
  const isLoggingIn = React.useMemo(() => {
    return loginStatus === 'loading' || logoutStatus === 'loading'
  }, [loginStatus, logoutStatus])

  const [state, setState] = React.useState({
    anchorEl: null,
    searchResultAnchor: null,
    clickedSearchResultId: "", 
    toggleSupport: false, 
    supportData:{
      slackHandler:  "", 
      email:  "" ,
      msg: ""
    }, 
    errorSupport:{
      slackHandler:  false, 
      email:  false ,
      msg: false
    }, 
  })

  const handleAccountClick = (event: any) => {
    setState({...state, anchorEl: event.currentTarget});
  };

  const handleAccountClose = () => {
    setState({...state, anchorEl: null});
  };

  const [mobileMenu, toggleMenu] = React.useState(false)

  return (
    <ClickAwayListener onClickAway={()=>toggleMenu(false)}>
      <AppBar
        elevation={0}
        color="transparent"
        position="fixed"
        style={{ height , background: "#fafafa"}}
        className="navContainer" >
        <Container  style={{ padding: 0, maxWidth: "100%" , height: "100%"}}>
          <div className={mobileMenu? "mobileNavigationContainer": "mobileNavigationContainer hide"}>
            <Link style={{color: "inherit", display: "flex", alignItems: "center", textDecoration: "none"}} to="/projects">
               PROJECTS
            </Link>
            <Link style={{color: "inherit", display: "flex", alignItems: "center", textDecoration: "none"}} to="/opportunity">
              OPPORTUNITIES
            </Link>
          </div>
          <Toolbar>
            <Grid
              container
              style={{ flexGrow: 1 }}
              alignItems="center"
              alignContent="flex-start">
                <Link style={{display: "flex"}} to="/projects">
                <img
                alt="Helpful Engineering logo"
                src={logoPath}
                className= "logoImg"
                style={{ width: 30, height: 30, margin: "0 8px 0 80px" }} />
                <MenuIcon onClick={()=>toggleMenu(!mobileMenu)} className="mobileNavMenu" />
              <Typography variant="h6">
                Helpful Hub
              </Typography>
              </Link>
            </Grid>
            <div className="navButtonContainer">
              <Button  color="primary" >
                <Link style={{color: "inherit", display: "flex", alignItems: "center", textDecoration: "none"}} to="/projects">
                  Projects
                </Link>
                </Button>

                <Button style={{ margin: "0 60px 0 50px" }} color="primary" >
                <Link style={{color: "inherit", display: "flex", alignItems: "center", textDecoration: "none"}} to="/opportunity">
                  Opportunities
                </Link>
                </Button>
            </div>
         
            {isLoggingIn &&
              <CircularProgress
              size={24}
              style={{ flexShrink: 0, marginRight: 16 }}
              color="primary" />
            }
            {currentAccountStatus !== 'loading' && preferencesStatus !== 'loading' && !currentAccount &&
              <OptionalTooltip
              disabled={preferences?.allowCookies ?? false}
              title="You must accept cookies in order to sign in">
                <span style={{ flexShrink: 0 }}>
                  <Button
                    style={{ flexShrink: 0, marginRight: 30}}
                    disabled={isLoggingIn || currentAccountIsFetching || preferencesIsFetching || !preferences?.allowCookies}
                    color="primary"
                    onClick={() => login()}
                    variant="outlined"
                    >
                    
                    Sign In
                  </Button>
                </span>
              </OptionalTooltip>
            }
            {currentAccount && !isLoggingIn &&
                <div className="accountContainer">
                    <MoreVertIcon onClick={handleAccountClick} className="accountDetailtsMobile" />
                    <Avatar onClick={handleAccountClick} src={currentAccount!.avatar.href} />
                    <Menu
                    className="accountDetailtsContainer"
                    id="accountInfo"
                    anchorEl={state.anchorEl}
                    keepMounted
                    open={Boolean(state.anchorEl)}
                    onClose={handleAccountClose}
                    >
                      <MenuItem  className="accountDetails" onClick={handleAccountClose}>  
                        <Avatar src={currentAccount!.avatar.href} />
                        <span className="slackHandle">
                        {currentAccount!.slackHandle.value[0] === "@"?currentAccount!.slackHandle.value: `@${ currentAccount!.slackHandle.value}`}
                        </span>
                        <span className="email"> {currentAccount!.email}</span>
                      </MenuItem>
                    {/* <MenuItem className="accountOptionBtn editProfile" onClick={handleAccountClose}> 
                        EDIT PROFILE            
                      </MenuItem> */}
                <MenuItem onClick={handleAccountClose}><div onClick={() => logout()} className="accountOptionBtn logout">
                    <span>LOGOUT</span>
                  </div></MenuItem>
                </Menu>
              </div>
            }
            {(currentAccountStatus === 'loading' || preferencesStatus === 'loading') &&
              <>
                <Skeleton variant="circle" width={40} height={40} style={{ marginRight: 8, flexShrink: 0 }} />
              </>
            }
          </Toolbar>
        </Container>
        <Divider />
      </AppBar>
            </ClickAwayListener>
     
    )
}
