import { immerable } from 'immer'
import type { DataFields } from '../Core/Typelevel'
import Timestamp from '../Core/Timestamp'

export default class Slack {
  public static [immerable] = true
  public readonly "Name"!: string 
  public readonly "Created"!: string | null
  public readonly "Total membership"!: number | null
  public readonly "Channel ID"!: string
  public readonly "Last Modified"!: string | null 
  public readonly "Slack Channel Link"!: string | null
  constructor(fields: DataFields<Slack>) {
    Object.assign(this, fields)
  }
}

export type SlackId = string


export class SlackMetadata {
  public static [immerable] = true

  public readonly created!: Timestamp

  constructor(fields: DataFields<SlackMetadata>) {
    Object.assign(this, fields)
  }
}
