import { immerable } from 'immer'
import type { DataFields } from '../Core/Typelevel'
import Timestamp from '../Core/Timestamp'

export default class Projects {
  public static [immerable] = true
  public readonly "Project Title"!: string 
  public readonly "Project Lead Slack Handle"!: string | null
  public readonly "Project Team Members Slack Handle(s)"!: string | null
  public readonly "Project Types"!: any
  public readonly Website!: string | null 
  public readonly "Project Documentation Link"!: string | null
  public readonly "Support from Helpful"!: string | null
  public readonly Summary!: string  | null
  public readonly "Team Size"!: number | null
  public readonly "Open Source Agreement"!: boolean | undefined | null
  public readonly "Slack Channel"!: any
  public readonly Stage!: string 
  public readonly "Created Time"!: string | null
  public readonly Freshness!: string | null
  public readonly "Last Modified Time"!: string | null
  public readonly "Last Updated"!: string | null
  public readonly "Highlighted Project"!: boolean | null
  public readonly Outputs!: string | null
  public readonly Updates!: string | null
  public readonly ID!: string| null
  public readonly Category!: string| null
  public readonly "Sub-category"!: string| null
  public readonly "QA-RA ID"!: string| null
  public readonly "Project Updates Notes"!: any
  public readonly "Slack Channel Name"!: string| null
  public readonly Logo!: any



  constructor(fields: DataFields<Projects>) {
    Object.assign(this, fields)
  }
}

export type ProjectId = string


export class ProjectsMetadata {
  public static [immerable] = true

  public readonly created!: Timestamp

  constructor(fields: DataFields<ProjectsMetadata>) {
    Object.assign(this, fields)
  }
}
