import React from 'react'
import GoogleAnalytics from 'react-ga';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Location } from 'history'
import { Container } from 'typedi'
import Auth0, { Auth0User } from '../Infrastructure/Auth0'
import IAnalytics, { AnalyticsEvent } from '../Domain/IAnalytics'

export default class Analytics implements IAnalytics {
  private auth0: Auth0
  private gaStarted: boolean = false
  private gaQueue: Array<() => void> = []
  private gaWorking: boolean = false
  private gaCurrentUser: Auth0User | null = null

  constructor(container: typeof Container) {
    this.auth0 = container.get(Auth0)
    Bugsnag.start({
      apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '0'.repeat(32), // use environment variable or random invalid key
      appVersion: process.env.REACT_APP_RELEASE_VERSION,
      enabledReleaseStages: [ 'production' ],
      releaseStage: process.env.NODE_ENV,
      plugins: [new BugsnagPluginReact(React)],
    })
  }

  getErrorBoundary(): React.ComponentType {
    return Bugsnag.getPlugin('react')
  }

  reportError({
    error,
    severity,
    metadata,
  }: {
    error: Error,
    severity?: 'info' | 'warning' | 'error',
    metadata?: Record<string, any>,
  }) {
    Bugsnag.notify(error, async (event) => {
      const user = await this.auth0.getUser()
      if (user) event.setUser(user.sub, user.email, user.name)
      if (severity) event.severity = severity
      if (!metadata) return
      Object.entries(metadata).forEach(([key, value]) => {
        event.addMetadata(key, value)
      })
    })
  }

  async trackPageChange(location: Location) {
    this.withGoogleAnalytics(() => {
      const page = location.pathname + location.search
      GoogleAnalytics.set({
        page,
        location: `${window.location.origin}${page}`,
      })
      GoogleAnalytics.pageview(page)
    })
  }

  trackEvent(event: AnalyticsEvent) {
    this.withGoogleAnalytics(() => {
      GoogleAnalytics.event(event)
    })
  }

  /**
   * Bugsnag has its own queuing mechanism for setting async details on events while still ensuring
   * that they happen in the correct order. GoogleAnalytics does not, so this is a manual queuing
   * mechanism to ensure that the user info is correct on all events and that they happen in the
   * order they were called, even if requesting the user info might otherwise cause a race
   * condition.
   */
  private withGoogleAnalytics(cb: () => void) {
    if (process.env.NODE_ENV !== 'production') return
    if (!this.gaStarted) {
      GoogleAnalytics.set({ anonymizeIp: true });
      GoogleAnalytics.initialize(process.env.REACT_APP_GA_TRACKING_ID || 'UA-000000000-1');
      this.gaStarted = true
    }
    this.gaQueue.push(cb)
    if (!this.gaWorking) {
      this.gaWorking = true
      this.processGoogleAnalytics()
    }
  }

  private async processGoogleAnalytics(): Promise<void> {
    const next = this.gaQueue.shift()
    
    if (!next) {
      this.gaWorking = false
      return
    }

    const user = await this.auth0.getUser()
    if (user?.sub !== this.gaCurrentUser?.sub) {
      GoogleAnalytics.set({
        user: user ?
          { id: user.sub, email: user.email, name: user.name } :
          undefined
      })
      this.gaCurrentUser = user
    }

    next()
    return this.processGoogleAnalytics()
  }
}
