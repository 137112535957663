import { Token } from 'typedi'
import Projects, { ProjectId } from './Projects'
import AccessToken from './AccessToken'


interface IProjectsRepository {
  projectsList(): Promise<ReadonlyArray<{ id: ProjectId, value: Projects }>>,
  submitProject(data: Projects, access_token:AccessToken): Promise<any>,
  verifyToken(token: AccessToken, id: ProjectId): Promise<any>
  getProjectById(id: ProjectId, access_token:AccessToken | null): Promise<any>
  updateProject(id: ProjectId, data:Projects, access_token:AccessToken | null): Promise<any>
  getProjectUpdatesById(id: ProjectId, access_token:AccessToken | null): Promise<any>
  submitProjectUpdate(id: ProjectId, data: any, access_token:AccessToken): Promise<any>,
}

namespace IProjectsRepository {
  export const token = new Token<IProjectsRepository>('IProjectsRepository')
}

export default IProjectsRepository
 