import 'typeface-work-sans'
import React from 'react'
import { createBrowserHistory } from 'history'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import loadable from '@loadable/component'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import "./styles.sass"
import Header from './Components/Header'
import Footer from './Components/Footer'
import {
  useTrackPageLoad,
  useAnalyticsErrorBoundary,
  usePreferences,
  useAcceptAnalytics, 
  useSendSupportMessage,
  useReportError
} from './Domain/UseCases'
import { Fab } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';



const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Work Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#2B3852'
    },
    secondary: {
      main: '#61CE70'
    }
  },
  shape: {
    borderRadius: 4
  }
})

const OpportunityApplication = loadable(() => import('./Pages/OpportunityApplication'))
const Partners = loadable(() => import('./Pages/Partners'))
const ProjectsRegistration = loadable(() => import('./Pages/ProjectRegister/ProjectsRegistration'))
const ProjectUpdate = loadable(() => import('./Pages/ProjectUpdate/ProjectUpdate'))
const ProjectsList = loadable(() => import('./Pages/ProjectsList/ProjectsLandingPage'))
const ProjectsLandingPage = loadable(() => import('./Pages/ProjectsList/ProjectsInfo'))


export default () => {
  const trackPageLoad = useTrackPageLoad()
  const [state, setState] = React.useState({ 
    toggleSupport: false, 
    supportData:{
      slackHandler:  "", 
      email:  "" ,
      msg: ""
    }, 
    errorSupport:{
      slackHandler:  false, 
      email:  false ,
      msg: false
    }, 
    validateSupportForm: false,
     sendData:{}
  })

  const [showUpButton, setShowUpButton] = React.useState(false)

  const history = React.useMemo(() => {
    return createBrowserHistory()
  }, [])

  let pagePosition: number = 0 
  window.addEventListener('scroll', (e:any)=>{
    pagePosition = window.scrollY
    if(pagePosition > 1500){
      setShowUpButton(true)
    }else{
      setShowUpButton(false)
    } 
  }, { passive: true })
 
  
  React.useEffect(() => {
    return history.listen(()=>{
      window.scrollTo(0,0)
      return trackPageLoad
    })  
  }, [trackPageLoad, history])

  const ErrorBoundary = useAnalyticsErrorBoundary()

  const { data: preferences } = usePreferences()
  const [acceptAnalytics] = useAcceptAnalytics()

  const resetFormValidation = ()=>{
    setState({
      ...state, 
      validateSupportForm: false
    })
  }
  const appBarHeight: number = 65
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <div
            style={{
              display: 'grid',
              gridTemplateRows: 'auto 1fr auto',
              minHeight: '100vh',
            }}>
            <Header height={appBarHeight} />
            <main style={{marginTop: appBarHeight}}>
              <Switch>
                <Route path="/opportunity" exact component={OpportunityApplication} />
                <Route path="/partners" exact component={Partners} />
                <Route path="/projects" exact component={ProjectsList} />
                <Route path="/projects/register" exact component={ProjectsRegistration} />
                <Route path="/projects/:id" exact component={ProjectsLandingPage} />
                <Route path="/projects/update/:id" exact component={ProjectUpdate} />
                <Route path="/*" render={() => <Redirect to="/projects" />} />
              </Switch>
            </main>
            <div className="goUpIcon">
              <Fab onClick={()=>{window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })}}  className={showUpButton? "goUpIcon" :"goUpIcon hide" }>
                  <ArrowUpwardIcon/>
              </Fab>
            </div>
            <Footer />
          </div>
        </Router>
        {
          state.validateSupportForm && <SendSupportMessage resetForm= {resetFormValidation} supportData={state.sendData} /> 
        }
        <Snackbar
          key="cookie-banner"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={preferences?.allowCookies === null}
          autoHideDuration={null}
          message="This website uses cookies to sign you in, and to analyze site performance and usage."
          action={
            <Button
              color="secondary"
              size="small"
              onClick={() => acceptAnalytics()}>
              Accept
            </Button>
          } />
      </ThemeProvider>
    </ErrorBoundary>
  )


 
}
const SendSupportMessage = (props:any) =>{
  const [open, setOpen] = React.useState(true);
  const closeTime: number = 5000
  const {
    status: submitSupportStatus,
  } = useSendSupportMessage(props.supportData)

  const reportError = useReportError()
  
  const isLoading: boolean = React.useMemo(() => {
    return submitSupportStatus === 'loading'
  }, [submitSupportStatus])
  const hasEssentialDataError: boolean = React.useMemo(() => {
    return submitSupportStatus === 'error'
  }, [submitSupportStatus])
  
  const isSuccess: boolean = React.useMemo(() => {
    return submitSupportStatus === 'success'
  }, [submitSupportStatus])

  React.useEffect(() => {
    if (!hasEssentialDataError) return 
    
    reportError({
      error: new Error('Failed to send support message'),
      metadata: { page: 'Application' }
    })
  }, [hasEssentialDataError, reportError])


  


  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    props.resetForm()
    setOpen(false);
  };
    if(isLoading) return <div className="supportLoadingContainer"> <CircularProgress color="secondary" size={30} /> <span className="loadingMessage">Sending your message...</span> </div>
    return(
      <Snackbar 
        open={open } 
        autoHideDuration={closeTime}
        onClose={handleClose} 
        className="snavkbarContainer"
        >
        <Alert 
           variant="filled"
          severity={
           !hasEssentialDataError? 'success': "error"
          } 
          onClose={handleClose}>
          {hasEssentialDataError && <span > Something went wrong with your request and your message wasn't sent. Please try again later.  </span>}
          {isSuccess && <span >Thank you for contacting us! Your message was posted on @questions-support-feedback slack channel. We will try to respond as soon as possible</span>}
        </Alert>
      </Snackbar>
    )
}
