import Support from "./Support";
import AccessToken from "./AccessToken";
import { Token } from 'typedi'


interface ISupportRepository {
  submitSupport(data: Support, access_token: AccessToken | null): Promise<any>,
}

namespace ISupportRepository {
  export const token = new Token<ISupportRepository>('ISupportRepository')
}

export default ISupportRepository
 