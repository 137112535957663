import React from 'react'
import logoPath from '../Assets/helpful_logo_alternative.png'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import ChatWidget from './ChatWidget'

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#F9F9F4' }}>
      <Divider />
      <Link
        target="_blank"
        href="https://helpfulengineering.org"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 64,
        }}>
        <img
          alt="Helpful Engineering logo"
          style={{ width: 200, marginBottom: 32 }}
          src={logoPath} />
        <Typography>Volunteering Against COVID-19</Typography>
      </Link>
      {process.env.REACT_APP_TAWKTO_ID && <ChatWidget
        id={process.env.REACT_APP_TAWKTO_ID || ''}
      ></ChatWidget> }
    </footer>
  )
}

export default Footer
