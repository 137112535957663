import { Token } from 'typedi'

import Account from '../Domain/Account'

interface IAuthentication {
  /**
   * Whether the current viewer is authenticated
   */
  isAuthenticated(): Promise<boolean>

  /**
   * Gets the Account for the currently logged in user, or null if anonymous
   */
  current(): Promise<Account | null>

  /**
   * Attempt to log the current viewer in
   */
  login(): Promise<void>

  /**
   * Attempt to log the current viewer out
   */
  logout(): Promise<void>
}

namespace IAuthentication {
  export const token = new Token<IAuthentication>('IAuthentication')
}

export default IAuthentication
