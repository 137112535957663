import ISupportRepository from '../Domain/ISupportRepository'
import AccessToken from '../Domain/AccessToken'

export default class SupportRepository implements ISupportRepository {
  private apiBaseUrl(): URL {
    const url = new URL(process.env.REACT_APP_API_BASE!)
    if (!url.pathname.endsWith('/')) url.pathname += '/'
    return url
  }

  async submitSupport(data:any , access_token: AccessToken | null = null): Promise<void> {
    const url = this.apiBaseUrl()
    url.pathname += 'helpful/support'

    // const response = await fetch(url.href, {
    //   method: 'POST',
    //   body: JSON.stringify(data),
    //   headers: {
    //     "Content-type": "application/json; charset=UTF-8", 
    //     Authorization: `Bearer ${access_token.value}`
    //   }
    //   })
    //   if (!response.ok) {
    //     throw new Error('failed to submit project')
    //   }
    
    return new Promise(((resolve, reject)=>{
      setTimeout(() => {
         resolve()
      }
      , 3000);
    }))
  }

}
