import { Container } from 'typedi'
import { createSchema as S, TsjsonParser, Validated } from 'ts-json-validator'
import Preferences from '../Domain/Preferences'
import IPreferencesRepository from '../Domain/IPreferencesRepository'
import Notifier from '../Infrastructure/Notifier'

export default class PreferencesRepository implements IPreferencesRepository {
  static storageKey: string = 'directory.helpful.PreferencesRepository.data'
  private notifier: Notifier<Preferences> = new Notifier()
  private storage: Storage
  private window: Window

  constructor(container: typeof Container) {
    this.window = container.get(Window)
    this.storage = container.get(Storage)
    this.window.addEventListener('storage', this.onLocalStorageChange)
  }

  load(): Promise<Preferences> {
    return new Promise((resolve) => {
      try {
        resolve(this.read() ?? Preferences.default)
      } catch {
        resolve(Preferences.default)
      }
    })
  }

  update(preferences: Preferences): Promise<void>  {
    return new Promise((resolve) => {
      this.storage.setItem(PreferencesRepository.storageKey, JSON.stringify({
        version: 2,
        allowCookies: preferences.allowCookies,
      }))
      this.publishChange()
      resolve()
    })
  }

  subscribe(cb: (preferences: Preferences) => void) {
    return this.notifier.subscribe(cb)
  }

  private read(): Preferences | null {
    const fromLocalStorage = this.storage.getItem(PreferencesRepository.storageKey)
    if (fromLocalStorage === null) return null
    const parser = new TsjsonParser(schema)
    const data: Validated<typeof schema> = parser.parse(fromLocalStorage)
    return migrate(data)
  }

  private onLocalStorageChange = async (event: StorageEvent) => {
    if (event.key !== PreferencesRepository.storageKey && event.key !== null) return
    this.publishChange()
  }

  private publishChange() {
    try {
      const data = this.read()
      this.notifier.publish(data ?? Preferences.default)
    } catch {}
  }

  dispose() {
    this.notifier.unsubscribeAll()
    this.window.removeEventListener('storage', this.onLocalStorageChange)
  }
}

const schemaV1 = S({
  type: 'object',
  required: [
    'allowAnalytics',
  ],
  properties: {
    allowAnalytics: S({
      oneOf: [
        S({ type: 'null' }),
        S({ type: 'boolean' }),
      ]
    })
  }
})

const schemaV2 = S({
  type: 'object',
  required: [
    'version',
    'allowCookies'
  ],
  properties: {
    version: S({ type: 'number', const: 2 }),
    allowCookies: S({
      oneOf: [
        S({ type: 'null' }),
        S({ type: 'boolean' }),
      ],
    }),
  },
})

const schema = S({
  oneOf: [
    schemaV1,
    schemaV2,
  ],
})

const isV1 = (value: Validated<typeof schema>): value is Validated<typeof schemaV1> => {
  return !value.hasOwnProperty('version')
}

const isV2 = (value: Validated<typeof schema>): value is Validated<typeof schemaV2> => {
  return value.version === 2
}

const migrate = (value: Validated<typeof schema>): Preferences => {
  if (isV1(value)) {
    return new Preferences({
      allowCookies: value.allowAnalytics,
    })
  } else if (isV2(value)) {
    return new Preferences({
      allowCookies: value.allowCookies,
    })
  } else {
    return Preferences.default
  }
}
