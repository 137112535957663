import { Token } from 'typedi'
import Opportunity, { OpportunityId } from '../Domain/Opportunity'
import AccessToken from '../Domain/AccessToken'

interface IOpportunitiesRepository {
  list(): Promise<ReadonlyArray<{ id: OpportunityId, value: Opportunity }>>
  submitApplication(inputs: {
    opportunityId: OpportunityId,
    accessToken: AccessToken,
  }): Promise<void>
}

namespace IOpportunitiesRepository {
  export const token = new Token<IOpportunitiesRepository>('IOpportunitiesRepository')
}

export default IOpportunitiesRepository
