import { immerable } from 'immer'
import { OpportunityId } from './Opportunity'

export default class Volunteer {
  static [immerable] = true

  public readonly timezone!: string
  public readonly weeklyCapacity!: number | null
  public readonly country!: string | null
  public readonly submittedApplications!: ReadonlySet<OpportunityId>

  public constructor(fields: Pick<Volunteer, 'timezone' | 'weeklyCapacity' | 'country' | 'submittedApplications'>) {
    Object.assign(this, fields)
  }
}
