import React from 'react'
import { Location } from 'history'
import { Token } from 'typedi'

export type AnalyticsEvent = {
  category: string,
  action: string,
}

export type AnalyticsErroReport = {
  error: Error,
  severity?: 'info' | 'warning' | 'error',
  metadata?: Record<string, any>,
}

interface IAnalytics {
  reportError(report: AnalyticsErroReport): void
  trackPageChange(location: Location): void
  getErrorBoundary(): React.ComponentType
  trackEvent(data: AnalyticsEvent): void
}

namespace IAnalytics {
  export const token = new Token<IAnalytics>('IAnalytics')
}

export default IAnalytics
