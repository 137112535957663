import { Container } from 'typedi'
import createAuth0Client, { Auth0Client, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js'

export type Auth0User = {
  email: string,
  name: string,
  nickname: string,
  picture: string,
  sub: string,
  updated_at: string,
}

export default class Auth0 {
  private window: Window
  private client: Auth0Client | null = null
  private clientPromise: Promise<Auth0Client> | null = null

  constructor(container: typeof Container) {
    this.window = container.get(Window)
  }

  async login(): Promise<void> {
    const client = await this.open()
    await client.loginWithPopup()
  }

  async logout(): Promise<void> {
    const client = await this.open()
    await client.logout({
      localOnly: true
    })
  }

  async isAuthenticated(): Promise<boolean> {
    const client = await this.open()
    return client.isAuthenticated()
  }

  async getUser(): Promise<Auth0User | null> {
    const client = await this.open()
    const user = await client.getUser()
    if (user === undefined) return null
    return user as Auth0User
  }

  async getTokenSilently(options?: GetTokenSilentlyOptions): Promise<string> {
    const client = await this.open()
    return client.getTokenSilently(options)
  }

  private open(): Promise<Auth0Client> {
    if (this.client) return Promise.resolve(this.client)
    else if (this.clientPromise) return this.clientPromise
    else {
      this.clientPromise = createAuth0Client({
        domain: process.env.REACT_APP_AUTH0_DOMAIN!,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!,
        redirect_uri: this.window.location.origin,
        authorizeTimeoutInSeconds: 10,
      }).then(async (client) => {
        this.client = client
        this.clientPromise = null
        return this.client
      })
      return this.clientPromise
    }
  }
}
