import { Token } from 'typedi'
import Preferences from '../Domain/Preferences'

interface IPreferencesRepository {
  load(): Promise<Preferences>
  update(preferences: Preferences): Promise<void>
  subscribe(cb: (preferences: Preferences) => void): (() => void)
}

namespace IPreferencesRepository {
  export const token = new Token<IPreferencesRepository>('IPreferencesRepository')
}

export default IPreferencesRepository
