import { createSchema as S, Validated } from 'ts-json-validator'
import AccessToken from '../Domain/AccessToken'
import Projects, { ProjectId } from '../Domain/Projects'
import IProjectsRepository from '../Domain/IProjectsRepository'

export default class ProjectsRepository implements IProjectsRepository {
  private apiBaseUrl(): URL {
    const url = new URL(process.env.REACT_APP_API_BASE!)
    if (!url.pathname.endsWith('/')) url.pathname += '/'
    return url
  }
 

  async verifyToken(token: AccessToken, id: ProjectId): Promise<any>{
    const url = this.apiBaseUrl().toString() + `helpful/projects?ID=${id}` 
    const response = await fetch(url)
    const json = await response.json()
    return json[0].fields.Token === token  
  }

  async getProjectById(id: ProjectId, access_token: AccessToken | null = null): Promise<any> {
    let url = this.apiBaseUrl().toString()
    url += `helpful/projects?ID=${id}`
    let headers = {
        Authorization: access_token ? `Bearer ${access_token.value}`: ""
    }
    const response = await fetch(url, {
      method: 'GET',
      headers
      })
    const json = await response.json()
   
    return json.map((item: Validated<typeof projectIdSchema>) => ({
      id: item.id,
      authorized: item.authorized ?? null,
      value: new Projects({
        "Project Title": item.fields["Project Title"], 
        "Project Lead Slack Handle": item.fields["Project Lead Slack Handle"] ?? null,
        "Project Team Members Slack Handle(s)": item.fields["Project Team Members Slack Handle(s)"] ?? null,
        "Project Types": item.fields["Project Types"],
        Website: item.fields.Website ?? null, 
        "Project Documentation Link": item.fields["Project Documentation Link"] ?? null, 
        "Support from Helpful": item.fields["Support from Helpful"] ?? null, 
        Summary: item.fields.Summary ?? null, 
        "Team Size": item.fields["Team Size"] ?? null, 
        "Open Source Agreement": item.fields["Open Source Agreement"], 
        "Slack Channel": item.fields["Slack Channel"] ?? null,
        Stage: item.fields.Stage, 
        "Created Time": item.fields["Created Time"]?? null,
        Freshness: item.fields.Freshness?? null,
        "Last Modified Time": item.fields["Last Modified Time"]?? null,
        "Last Updated": item.fields["Last Updated"]?? null,
        "Highlighted Project": item.fields["Highlighted Project"]?? null,
        Outputs: item.fields.Outputs?? null,
        Updates: item.fields.Updates?? null,
        ID: item.fields.ID?? null,
        Category: item.fields.Category?? null,
        "Sub-category": item.fields["Sub-category"]?? null,
        "QA-RA ID": item.fields["QA-RA ID"]?? null,
        "Project Updates Notes": item.fields["Project Updates Notes"]?? null,
        "Slack Channel Name": item.fields["Slack Channel Name"]?? null,
        Logo: item.fields.Logo ?? null      
      })      
    }))
  }


  async getProjectUpdatesById(id: ProjectId, access_token: AccessToken | null = null): Promise<any> {
    let url = this.apiBaseUrl().toString()
    url += `helpful/projects/${id}/updates`
    let headers = {
        Authorization: access_token ? `Bearer ${access_token.value}`: ""
    }
    const response = await fetch(url, {
      method: 'GET',
      headers
      })
    const json = await response.json()
   
    return json.map((item: Validated<typeof projectIdSchema>) => ({
      id: item.id,
      fields: new Projects({
        "Project Title": item.fields["Project Title"]?? null, 
        "Project Lead Slack Handle": item.fields["Project Lead Slack Handle"] ?? null,
        "Project Team Members Slack Handle(s)": item.fields["Project Team Members Slack Handle(s)"] ?? null,
        "Project Types": item.fields["Project Types"]?? null,
        Website: item.fields.Website ?? null, 
        "Project Documentation Link": item.fields["Project Documentation Link"] ?? null, 
        "Support from Helpful": item.fields["Support from Helpful"] ?? null, 
        Summary: item.fields.Summary ?? null, 
        "Team Size": item.fields["Team Size"] ?? null, 
        "Open Source Agreement": item.fields["Open Source Agreement"]?? null, 
        "Slack Channel": item.fields["Slack Channel"] ?? null,
        Stage: item.fields.Stage ?? null, 
        "Created Time": item.fields["Created Time"]?? null,
        Freshness: item.fields.Freshness?? null,
        "Last Modified Time": item.fields["Last Modified Time"]?? null,
        "Last Updated": item.fields["Last Updated"]?? null,
        "Highlighted Project": item.fields["Highlighted Project"]?? null,
        Outputs: item.fields.Outputs?? null,
        Updates: item.fields.Updates?? null,
        ID: item.fields.ID?? null,
        Category: item.fields.Category?? null,
        "Sub-category": item.fields["Sub-category"]?? null,
        "QA-RA ID": item.fields["QA-RA ID"]?? null,
        "Project Updates Notes": item.fields["Project Updates Notes"]?? null,
        "Slack Channel Name": item.fields["Slack Channel Name"]?? null,   
        Logo: item.fields.Logo ?? null
      }), 
      createdTime: item.createdTime       
    }))
  }

  

  async updateProject(id: ProjectId, data: any, access_token: AccessToken): Promise<any> {
    let url = this.apiBaseUrl()
    url.pathname += `helpful/projects/${id}`
    const response = await fetch(url.href, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers:{
        "Content-type": "application/json; charset=UTF-8", 
        Authorization: `Bearer ${access_token.value}` 
      }
      })
      if (!response.ok) {
        throw new Error('failed to update project')
      }  
    
  }
  async submitProjectUpdate(id: ProjectId, data: any, access_token: AccessToken): Promise<any> {
    let url = this.apiBaseUrl()
    url.pathname += `helpful/projects/${id}/updates`
    const response = await fetch(url.href, {
      method: 'POST',
      body: JSON.stringify(data),
      headers:{
        "Content-type": "application/json; charset=UTF-8", 
        Authorization: `Bearer ${access_token.value}` 
      }
      })
      if (!response.ok) {
        throw new Error('failed to update project')
      }  
    
  }

  async projectsList(): Promise<Array<{ id: ProjectId, value: Projects }>> {
    const url = this.apiBaseUrl()
    url.pathname += 'helpful/projects'
    const response = await fetch(url.href)
    const json = await response.json()
    

    return json.map((item: Validated<typeof projectIdSchema>) => ({
      id: item.id,
      authorized: item.authorized ?? null,
      value: new Projects({
        "Project Title": item.fields["Project Title"], 
        "Project Lead Slack Handle": item.fields["Project Lead Slack Handle"] ?? null,
        "Project Team Members Slack Handle(s)": item.fields["Project Team Members Slack Handle(s)"] ?? null,
        "Project Types": item.fields["Development Type"] ?? null,
        Website: item.fields.Website ?? null, 
        "Project Documentation Link": item.fields["Project Documentation Link"] ?? null, 
        "Support from Helpful": item.fields["Support from Helpful"] ?? null, 
        Summary: item.fields.Summary ?? null, 
        "Team Size": item.fields["Team Size"] ?? null, 
        "Open Source Agreement": item.fields["Open Source Agreement"], 
        "Slack Channel": item.fields["Slack Channel"] ?? null,
        Stage: item.fields.Stage, 
        "Created Time": item.fields["Created Time"]?? null,
        Freshness: item.fields.Freshness?? null,
        "Last Modified Time": item.fields["Last Modified Time"]?? null,
        "Last Updated": item.fields["Last Updated"]?? null,
        "Highlighted Project": item.fields["Highlighted Project"]?? null,
        Outputs: item.fields.Outputs?? null,
        Updates: item.fields.Updates?? null,
        ID: item.fields.ID?? null,
        Category: item.fields.Category?? null,
        "Sub-category": item.fields["Sub-category"]?? null,
        "QA-RA ID": item.fields["QA-RA ID"]?? null,
        "Project Updates Notes": item.fields["Project Updates Notes"]?? null,
        "Slack Channel Name": item.fields["Slack Channel Name"]?? null,
         Logo: item.fields.Logo ?? null
      })      
    }))
  }

  async submitProject(data:any , access_token: AccessToken): Promise<void> {
    const url = this.apiBaseUrl()
    url.pathname += 'helpful/projects/register'
    data["Project Lead Slack Handle"] =  data["Project Lead Slack Handle"].toString()
    data["Support from Helpful"] =  data["Support from Helpful"].toString()
    const response = await fetch(url.href, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8", 
        Authorization: `Bearer ${access_token.value}`
      }
      })
      if (!response.ok) {
        throw new Error('failed to submit project')
      }  
  }
}


const projectIdSchema = S({
  type: 'object',
  required: [ 'id', 'createdTime', 'fields' ],
  properties: {
    id: S({ type: 'string' }),
    authorized: S({type: "boolean"}),
    createdTime: S({ type: 'string', format: 'date-time' }),
    fields: S({
      type: 'object',
      required: [
        "Project Title", 
        "Stage", 
      ],
      properties: {
        "Project Title":S({ type: 'string' }),
        "Summary": S({ type: 'string' }),
        "Team Size": S({ type: 'number' }),
        "Open Source Agreement": S({ type: 'boolean' }),
        "Additional Information": S({ type: 'string' }),
        "Project Lead Slack Handle": S({ type: 'string' }),
        "Autonumber": S({ type: 'number' }),
        "Slack Channel": S({  "type": "array" }),
        "Stage": S({ type: 'string' }),
        "Website": S({ type: 'string' }),
        "Step 1 Lead confirmation": S({ type: 'boolean' }),
        "Step 2 - update form+token sent": S({ type: 'boolean' }),
        "Category": S({ type: 'string' }),
        "Sub-category": S({ type: 'string' }),
        "Title": S({ type: 'string' }),
        "Hours Since Last Modified": S({ type: 'number' }),
        "Created Time": S({ type: 'string' }),
        "Freshness": S({ type: 'string' }),
        "Last Updated": S({ type: 'string' }),
        "Token": S({ type: 'string' }),
        "Slack Channel Needs Updating": S({ type: 'number' }),
        "Last Modified Time": S({ type: 'string' }),
        "Record ID": S({ type: 'string' }),
        "Slack Channel Membership": S({ type: 'array' }),
        "Slack Channel Name": S({ type: 'string' }),
        "Is Highlighted Project": S({ type: 'string' }),
        "ID": S({ type: 'string' }),
        Updates:S({type: "string"}),
        Outputs: S({ type: 'string' }), 
        "Highlighted Project": S({ type: 'boolean' }), 
        "Project Team Members Slack Handle(s)": S({ type: 'string' }),
        "Project Types": S({ type: 'array' }),
        "Project Documentation Link": S({ type: 'string' }), 
        "Support from Helpful": S({ type: 'string' }), 
        "Project Update Records": S({ type: 'array' }), 
        "QA-RA ID": S({ type: 'string' }),     
        "Project Updates Notes": S({ type: 'array' }),
        "Logo": S({ type: 'array' })

      }
  })
}
})
