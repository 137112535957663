import React from 'react'
import { useQuery, queryCache, QueryResult, useMutation, MutateFunction, MutationResult } from 'react-query'
import { produce } from 'immer'
import { Location } from 'history'
import { Container } from 'typedi'
import Preferences from './Preferences'
import Account from './Account'
import Opportunity, { OpportunityId } from './Opportunity'
import IAnalytics, { AnalyticsEvent } from './IAnalytics'
import IAuthentication from './IAuthentication'
import IOpportunitiesRepository from './IOpportunitiesRepository'
import IPreferencesRepository from './IPreferencesRepository'
import Volunteer from './Volunteer'
import IVolunteersRepository from './IVolunteersRepository'
import {ProjectId} from './Projects'
import IProjectsRepository from './IProjectsRepository'
import ISlackChannelsRepository from './ISlackChannelsRepository'
import ISupportRepository from './ISupportRepository'
import AccessToken from './AccessToken'
import Support from './Support'




export const useLogin = (): [MutateFunction<void, void>, MutationResult<void>] => {
  const auth = Container.get(IAuthentication.token)
  const preferences = Container.get(IPreferencesRepository.token)
  const reportError = useReportError()
  const trackEvent = useTrackEvent()
  return useMutation(async () => {
    const p = await preferences.load()
    if (!p.allowCookies) throw Error('Cannot sign in without allowing cookies')
    await auth.login()
  }, {
    onMutate: () => {
      queryCache.getQuery(['accounts', 'current'])?.setData(null)
    },
    onError: (error) => {
      reportError({ error: error as Error, metadata: { mutation: 'Login' }})
    },
    onSuccess: () => {
      trackEvent({ category: 'User', action: 'Logged In' })
    },
    onSettled: async () => {
      await Promise.all([
        queryCache.refetchQueries(['accounts', 'current']),
        queryCache.refetchQueries(['volunteers', 'current']),
      ])
    },
  })
}

export const useLogout = (): [MutateFunction<void, void>, MutationResult<void>] => {
  const auth = Container.get(IAuthentication.token)
  const reportError = useReportError()
  const trackEvent = useTrackEvent()
  return useMutation(() => auth.logout(), {
    onMutate: () => {
      queryCache.getQuery(['accounts', 'current'])?.setData(null)
    },
    onError: (error) => {
      reportError({ error: error as Error, metadata: { mutation: 'Logout' } })
    },
    onSuccess: () => {
      trackEvent({ category: 'User', action: 'Logged Out' })
    },
    onSettled: async () => {
      await Promise.all([
        queryCache.refetchQueries(['accounts', 'current']),
        queryCache.refetchQueries(['volunteers', 'current']),
      ])
    },
  })
}

export const useGetProejctStages = ()  =>{
  return ([
    {
      name: "Concept",
      color: "#CFDFFF"
    },
    {
      name: "Design",
      color: "#D0F0FD"
    }, 
    {
      name: "Prototype",
      color: "#C2F5E9"
    }, 
    {
      name: "Testing",
      color: "#D1F7C4"
    }, 
    {
      name: "Documenting/Approval",
      color: "#FFDA83"
    }, 
    {
      name: "MVP",
      color: "#FEE2D5"
    }, 
  ])
}

export const useGetProjectsCategories = ()  =>{
  return ([	"Ventilator-related", "PPE","Medical Testing", "Software Application/Website", "Other" ])
}

export const useGetHelpfulHelp = (list:any)  =>{
  const helpfullSupport = ["Project Management", "QA/RA","Recruiting", "Financial/Fundraising", "Technical Expertise", "Medical Expertise", "Communications/Media" ]
  return (list?helpfullSupport.filter(x => !list.includes(x)): helpfullSupport)
}

export const useSendSupportMessage = (data: Support): QueryResult<any> =>{
  const received_data = Container.get(ISupportRepository.token)
  const reportError = useReportError()
  const authentication = Container.get(IAuthentication.token)
  return useQuery({
    queryKey: ['submit_support'],
    queryFn: async () => {
   
      const account = await authentication.current()
      account ? await received_data.submitSupport(data, account!.accessToken)
              : await received_data.submitSupport(data, null)

    },
    config: { 
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (res => {
        return res
      }),
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: {
            mutation: 'supportSubmition',
          }
        })
      },
    }
  })
}

export const useProjectUpdateTokenValidation = (token: AccessToken, id: ProjectId): QueryResult<any> => {
  const token_validation = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['update_token'],
    queryFn: async () => {
      let response = await token_validation.verifyToken(token, id)
      return response
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'AllProjects' }
        })
      },
    }
  })

} 

export const useCurrentAccount = (): QueryResult<Account | null> => {
  const authentication = Container.get(IAuthentication.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['accounts', 'current'],
    queryFn: async () => authentication.current(),
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'CurrentAccount' }
        })
      }
    }
  })
}

export const useAllOpportunities = (): QueryResult<ReadonlyMap<OpportunityId, Opportunity>> => {
  const opportunities = Container.get(IOpportunitiesRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['opportunities'],
    queryFn: async () => {
      const items = await opportunities.list()
      return new Map(
        items.map(({id, value}) => [id, value]),
      )
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (opportunities) => {
        opportunities.forEach((value, id) => {
          queryCache.setQueryData(['opportunities', id], value)
        })
      },
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'AllOpportunities' }
        })
      },
    }
  })
}

export const useAllProjects = (): QueryResult<any> => {
  const projects = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['projects'],
    queryFn: async () => {
      const items = await projects.projectsList()
      return (items)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (projects) => {
        projects.forEach((value, id) => {
          queryCache.setQueryData(['projects', id], value)
        })
      },
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'AllProjects' }
        })
      },
    }
  })
}

export const useGetSlackChannels = (): QueryResult<any> => {
  const slack = Container.get(ISlackChannelsRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['slack'],
    queryFn: async () => {
      const items = await slack.getSlackChannels()
      return (items)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (channels) => {
        channels.forEach((value, id) => {
          queryCache.setQueryData(['channels', id], value)
        })
      },
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'SlackChannels' }
        })
      },
    }
  })
}

export const useGetProjectName = (id:any): QueryResult<Object> =>{
  const projects = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['projects_name'],
    queryFn: async () => {
      const items = await projects.getProjectById(id, null)
     
      if(items.length === 0){
        throw new Error('The project id you submitted is not registered')
      }
      return (items[0].value["Project Title"])
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'AllProjects' }
        })
      },
    }
  })
}


export const useGetProjectById = (id:string): QueryResult<Object> =>{
  const projects = Container.get(IProjectsRepository.token)
  const authentication = Container.get(IAuthentication.token)

  const reportError = useReportError()
  return useQuery({
    queryKey: ['projects_by_id'],
    queryFn: async () => {
      const account = await authentication.current()
      const item = account ? await projects.getProjectById(id, account!.accessToken)
              : await projects.getProjectById(id, null)
      if(item.length === 0){
        throw new Error('The project id you submitted is not registered')
      }
      return (item)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'ProjectByID' }
        })
      },
    }
  })
}

export const useGetProjectUpdatesById = (id:string): QueryResult<Object> =>{
  const projects = Container.get(IProjectsRepository.token)
  const authentication = Container.get(IAuthentication.token)

  const reportError = useReportError()
  return useQuery({
    queryKey: ['projects_updates_by_id'],
    queryFn: async () => {
      const account = await authentication.current()
      const item = account ? await projects.getProjectUpdatesById(id, account!.accessToken)
              : await projects.getProjectUpdatesById(id, null)
      return (item)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'ProjectUpdatesByID' }
        })
      },
    }
  })
}


export const useCheckProjectName = (projName: string): QueryResult<Object> =>{
  const projects = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['projects_by_name'],
    queryFn: async () => {
      const items = await projects.projectsList()
      let project = items.filter(proj => proj["value"]["Project Title"] === projName)
      return (project)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'AllProjects' }
        })
      },
    }
  })
}

export const useCurrentVolunteer = (): QueryResult<Volunteer | null> => {
  const authentication = Container.get(IAuthentication.token)
  const volunteers = Container.get(IVolunteersRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['volunteers', 'current'],
    queryFn: async () => {
      const account = await authentication.current()
      if (!account) return null
      return volunteers.current(account.accessToken)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'CurrentVolunteer' }
        })
      },
    }
  })
}

export const useRegisterProject = (data: any): QueryResult<void> =>{
  const received_data = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  const authentication = Container.get(IAuthentication.token)
  
  return useQuery({
    queryKey: ['project_register'],
    queryFn: async () => {
      const account = await authentication.current()
      const response = await received_data.submitProject(data, account!.accessToken)
      return response
    },
    config: { 
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (res => {
        return res
      }),
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: {
            mutation: 'RegisterProject',
          }
        })
      },
    }
  })
}


export const useUpdateProject = (id:ProjectId, data: any): QueryResult<void> =>{
  const received_data = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  const authentication = Container.get(IAuthentication.token)
  
  return useQuery({
    queryKey: ['project_update'],
    queryFn: async () => {
      const account = await authentication.current()
      const response = await received_data.updateProject(id,data, account!.accessToken)
      return response
    },
    config: { 
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (res => {
        return res
      }),
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: {
            mutation: 'RegisterProject',
          }
        })
      },
    }
  })
}

export const useSubmitProjectUpdate = (id:ProjectId, data: any): QueryResult<void> =>{
  const received_data = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  const authentication = Container.get(IAuthentication.token)
  
  return useQuery({
    queryKey: ['submit_update'],
    queryFn: async () => {
      const account = await authentication.current()
      const response = await received_data.submitProjectUpdate(id,data, account!.accessToken)
      return response
    },
    config: { 
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (res => {
        return res
      }),
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: {
            mutation: 'RegisterProject',
          }
        })
      },
    }
  })
}

export const useApplyToOpportunityAsCurrentAccount = (): [
  MutateFunction<void, OpportunityId>,
  MutationResult<void>,
] => {
  const authentication = Container.get(IAuthentication.token)
  const opportunities = Container.get(IOpportunitiesRepository.token)
  const reportError = useReportError()
  const trackEvent = useTrackEvent()
  return useMutation(async (opportunityId) => {
    const account = await authentication.current()
    if (!account) throw Error('Not authenticated')
    await opportunities.submitApplication({
      opportunityId,
      accessToken: account.accessToken,
    })
  }, {
    onSuccess: (_, opportunityId) => {
      trackEvent({
        category: 'User',
        action: 'Submitted an Application'
      })
      return queryCache.refetchQueries(['volunteers', 'current'])
    },
    onError: (error, args) => {
      reportError({
        error: error as Error,
        metadata: {
          arguments: args,
          mutation: 'ApplyToOpportunityAsCurrentAccount',
        }
      })
    }
  })
}

export const usePreferences = (): QueryResult<Preferences> => {
  const preferencesRepository = Container.get(IPreferencesRepository.token)
  const reportError = useReportError()

  React.useEffect(() => {
    return preferencesRepository.subscribe((preferences) => {
      queryCache.setQueryData(['preferences'], preferences)
    })
  }, [preferencesRepository])

  return useQuery({
    queryKey: ['preferences'],
    queryFn: () => preferencesRepository.load(),
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'Preferences' },
        })
      }
    }
  })
}

export const useAcceptAnalytics = (): [
  MutateFunction<void, void>,
  MutationResult<void>,
] => {
  const preferencesRepository = Container.get(IPreferencesRepository.token)
  const reportError = useReportError()
  return useMutation(async () => {
    const preferences = await preferencesRepository.load()
    const updated = produce(preferences, (p => {
      p.allowCookies = true
    }))
    await preferencesRepository.update(updated)
  }, {
    onError: (error) => {
      reportError({
        error: error as Error,
        metadata: { mutation: 'AcceptAnalytics' }
      })
    },
    onSettled: () => {
      return queryCache.refetchQueries(['preferences'])
    }
  })
}

export const useReportError = () => {
  const preferencesRepository = Container.get(IPreferencesRepository.token)
  const analytics = Container.get(IAnalytics.token)
  return React.useCallback(async (data: {
    error: Error,
    severity?: 'info' | 'warning' | 'error',
    metadata?: Record<string, any>,
  }) => {
    const preferences = await preferencesRepository.load()
    if (!preferences.allowCookies) return
    analytics.reportError(data)
  }, [preferencesRepository, analytics])
}

export const useTrackPageLoad = () => {
  const analytics = Container.get(IAnalytics.token)
  return React.useCallback(async (location: Location) => {
    analytics.trackPageChange(location)
  }, [analytics])
}

export const useTrackEvent = () => {
  const preferencesRepository = Container.get(IPreferencesRepository.token)
  const analytics = Container.get(IAnalytics.token)
  return React.useCallback(async (event: AnalyticsEvent) => {
    const preferences = await preferencesRepository.load()
    if (!preferences.allowCookies) return
    analytics.trackEvent(event)
  }, [preferencesRepository, analytics])
}

export const useAnalyticsErrorBoundary = (): React.ComponentType => {
  const preferencesRepository = Container.get(IPreferencesRepository.token)
  const analytics = Container.get(IAnalytics.token)

  const { data: preferences } = useQuery({
    queryKey: ['preferences'],
    queryFn: async () => preferencesRepository.load(),
  })

  const allowCookies = React.useMemo(() => {
    return preferences?.allowCookies ?? false
  }, [preferences])

  return React.useMemo(() => {
    if (allowCookies) return analytics.getErrorBoundary()
    else return React.Fragment
  }, [allowCookies, analytics])
}
