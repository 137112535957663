import { Token } from 'typedi'
import AccessToken from './AccessToken'


interface ISlackChannelsRepository {
  getSlackChannels(): Promise<ReadonlyArray<{access_token: AccessToken }>>,
}

namespace ISlackChannelsRepository {
  export const token = new Token<ISlackChannelsRepository>('ISlackChannelsRepository')
}

export default ISlackChannelsRepository
 