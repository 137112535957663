import AccessToken from './AccessToken'
import SlackHandle from './SlackHandle'

export default class Account {
  public readonly email!: string
  public readonly nickname!: string
  public readonly accessToken!: AccessToken
  public readonly slackHandle!: SlackHandle
  public readonly slackId!: string
  public readonly avatar!: URL

  public constructor(fields: Pick<Account, 'nickname' | 'accessToken' | 'email' | 'slackHandle' | 'slackId' | 'avatar'>) {
    Object.assign(this, fields)
  }
}
